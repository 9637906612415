import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { CustomMaterialModule } from "../custom-material/custom-material.module";
import { LimitToPipe } from "./pipes/limit-to.pipe";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { ContentPlaceholderAnimationComponent } from "./content-placeholder-animation/content-placeholder-animation.component";
import { LocalDatePipe } from "./pipes/local-date.pipe";
import { YesNoPipe } from "./pipes/yes-no.pipe";
import { LayoutComponent } from "./layout/layout.component";
import { ListUserComponent } from "./list-user/list-user.component";
import { ChatMessageComponent } from "./chat-message/chat-message.component";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { AwayMessageDialogComponent } from "./away-message-dialog/away-message-dialog.component";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { QuillModule } from "ngx-quill";
import { ReflectionTagcloudComponent } from "./reflection-tagcloud/reflection-tagcloud.component";
import { StatisticsPosNegComponent } from "./statistics-pos-neg/statistics-pos-neg.component";
import { PlotlyViaWindowModule } from "angular-plotly.js";
import { NgxMasonryModule } from "ngx-masonry";
import { TranslateModule } from "@ngx-translate/core";
import { InfoMessageDialogComponent } from "./info-message-dialog/info-message-dialog.component";
import { EncryptionSetupDialogComponent } from "./encryption-setup-dialog/encryption-setup-dialog.component";

@NgModule({
  imports: [
    RouterModule,
    CustomMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatTableModule,
    MatDialogModule,
    TranslateModule,
    NgxMaterialTimepickerModule,
    QuillModule,
    PlotlyViaWindowModule,
    NgxMasonryModule,
  ],
  declarations: [
    ConfirmDialogComponent,
    ContentPlaceholderAnimationComponent,
    LimitToPipe,
    LocalDatePipe,
    YesNoPipe,
    LayoutComponent,
    ListUserComponent,
    ChatMessageComponent,
    AwayMessageDialogComponent,
    ReflectionTagcloudComponent,
    StatisticsPosNegComponent,
    InfoMessageDialogComponent,
    EncryptionSetupDialogComponent,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    CustomMaterialModule,
    LimitToPipe,
    ConfirmDialogComponent,
    ContentPlaceholderAnimationComponent,
    LocalDatePipe,
    YesNoPipe,
    ChatMessageComponent,
    ListUserComponent,
    AwayMessageDialogComponent,
    ReflectionTagcloudComponent,
    StatisticsPosNegComponent,
    InfoMessageDialogComponent,
    EncryptionSetupDialogComponent,
  ],
})
export class SharedModule {}
