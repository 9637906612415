import { Injectable } from "@angular/core";
import * as openpgp from "openpgp";
import { StorageService } from "./storage.service";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class EncryptionService {
  constructor(private storageService: StorageService) {}
  pgpStorageKey = "pgpData";
  keysSet = false;
  serverKeyPassphrase = "";
  serverPublicKey = ``;

  myPrivateKey;
  myPublicKey;

  setEncryptionPassphrase(passphrase) {
    this.serverKeyPassphrase = passphrase;
  }

  async setKeys(pin = null, retry = false) {
    console.log("SET KEYS", pin, retry);
    const pgpData: any = this.storageService.getStorageObject(
      this.pgpStorageKey,
      false
    );
    if (pgpData) {
      this.myPublicKey = await openpgp.readKey({
        armoredKey: pgpData.publicKey,
      });

      if (!this.serverKeyPassphrase || this.serverKeyPassphrase === "") {
        console.log("GEEN SERVER PASSPHRASE ");
        setTimeout(async () => {
          this.serverKeyPassphrase = environment.passphrase;
          await this.setKeys(null, retry);
        }, 2500);
        return;
      }
      const decrypt: any = {
        privateKey: await openpgp.readPrivateKey({
          armoredKey: pgpData.privateKey,
        }),
        passphrase: this.serverKeyPassphrase,
      };

      if (this.storageService.dashboardType === "chat") {
        let tryPGP = await openpgp.decryptKey(decrypt).catch(async (err) => {
          if (err) {
            console.log(err);
            if (
              err.message.includes("Incorrect key passphrase") ||
              err.message.includes("No key or password") ||
              err.message.includes("Cannot decrypt")
            ) {
              let newPassphrase;
              if (environment.passphrase) {
                newPassphrase = environment.passphrase;
              } else {
                newPassphrase = prompt(
                  "Incorrect key passphrase, please try again"
                );
              }
              if (newPassphrase && !retry) {
                this.setEncryptionPassphrase(newPassphrase);
                await this.setKeys(null, retry);
              }
              return;
            }
          }
        });

        if (tryPGP) {
          this.myPrivateKey = tryPGP;
        }
        if (this.myPrivateKey) {
          this.keysSet = !!this.myPrivateKey;
          return this.myPrivateKey;
        } else {
          throw new Error("Cannot decrypt key");
        }
      }
    }
    console.log("END SET KEYS");
  }

  async setWebKeys(pin = null) {
    const pgpData: any = this.storageService.getStorageObject(
      this.pgpStorageKey,
      false
    );
    if (pgpData) {
      this.myPublicKey = await openpgp.readKey({
        armoredKey: pgpData.publicKey,
      });

      const decrypt: any = {
        privateKey: await openpgp.readPrivateKey({
          armoredKey: pgpData.privateKey,
        }),
        passphrase: pin,
      };

      this.myPrivateKey = await openpgp.decryptKey(decrypt).catch((err) => {
        console.log("error", err);
      });

      this.keysSet = true;
    }
  }

  async generateWebKeys(pin) {
    const pgpData: any = this.storageService.getStorageObject(
      this.pgpStorageKey,
      false
    );
    if (!pgpData) {
      const options: openpgp.KeyOptions | any = {
        type: "ecc", // Type of the key, defaults to ECC
        curve: "curve25519", // ECC curve name, defaults to curve25519
        userIDs: [{}], // you can pass multiple user IDs
        passphrase: pin, // protects the private key
        format: "armored", // output key format, defaults to 'armored' (other options: 'binary' or 'object')
      };
      const { privateKey, publicKey, revocationCertificate } =
        await openpgp.generateKey(options);

      this.storageService.setStorageObject(this.pgpStorageKey, {
        privateKey: privateKey,
        publicKey: publicKey,
      });
    }
    await this.setWebKeys(pin);
  }

  async decryptMessage(encryptedText) {
    if (!encryptedText.startsWith("-----BEGIN PGP MESSAGE")) {
      return encryptedText;
    }
    const pgpData = this.storageService.getStorageObject(
      this.pgpStorageKey,
      false
    );
    if (pgpData) {
      const message = await openpgp.readMessage({
        armoredMessage: encryptedText,
      });
      const decrypted: any = await openpgp.decrypt({
        message,
        verificationKeys: this.myPublicKey, // optional
        decryptionKeys: this.myPrivateKey,
      });
      const chunks = [];
      for await (const chunk of decrypted.data) {
        chunks.push(chunk);
      }
      return chunks.join("");
    }
    return "cannot decrypt";
  }

  async encryptMessage(text, userKey) {
    userKey = await openpgp.readKey({
      armoredKey: userKey,
    });
    const message = await openpgp.createMessage({ text: text });
    return await openpgp
      .encrypt({
        message: message,
        encryptionKeys: [this.myPublicKey, userKey],
        signingKeys: this.myPrivateKey,
      })
      .catch((err) => {
        console.log("error", err);
      });
  }
}
